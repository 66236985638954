import React, { Component } from 'react';
import { AppContext } from '../../context/AppContext';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export default class Overview extends Component {
  static contextType = AppContext;
  render() {
    let options = {month: 'short', day: 'numeric'};
    let startDate = new Date(this.context.currentWeek).toLocaleString('en-US', options);
    let endDate = new Date(this.context.currentWeek);
    endDate.setDate(endDate.getDate() + 6);
    endDate = endDate.toLocaleString('en-US', options);
    let startDate1 = new Date(this.context.nextWeek).toLocaleString('en-US', options);
    let endDate1 = new Date(this.context.nextWeek);
    endDate1.setDate(endDate1.getDate() + 6);
    endDate1 = endDate1.toLocaleString('en-US', options);
    console.log(endDate1);
    return <div className='overview-page'>
        {/* <h3 className='week-of'><span className="highlight">{startDate} - {endDate}</span></h3> */}
         <Tabs defaultActiveKey="next">
             <TabPane tab={`${startDate} - ${endDate}`} key="current">
                 <Tabs defaultActiveKey="1">
                    {this.context.currentWeekRoster.map((day, dayIndex) => {
                        return <TabPane tab={day.label.slice(0, 3)} key={day.label}>
                            <div className="slot-overview">
                                {day.slots.map((slot,slotIndex) => {
                                    /* let slotInfo = slotCount.find(info => {
                                        return info.slotLabel === slot.label && info.day === dayIndex
                                    })
                                    if(slotIndex === 0 || slot.label !== day.slots[slotIndex - 1].label) {
                                        return <React.Fragment><h3>{slot.label} - <span>{slotInfo.filled}/{slotInfo.total}</span></h3><hr></hr>{slot.isFilled? <span>{slot.filledByName}</span>: null}</React.Fragment>
                                    }
                                    else if(slot.isFilled) {
                                        return <span>{slot.filledByName}</span>
                                    }
                                    else {
                                        return null;
                                    } */
                                    return <div className='slot-box'>{slot.filledBy.map(user => <span className='overview-user-row'>{user.userName}<button onClick={() => this.context.cancelSlot(dayIndex, slotIndex, user.userId, 'currentWeek', 'currentWeekRosterID', 'currentWeekRoster')}>cancel</button></span>)}<hr></hr><h3>{slot.label} - <span>{slot.filledBy.length}/{slot.maxSlotSize}</span></h3></div>
                                })}
                            </div>
                        </TabPane>
                    })}
                         </Tabs>
             </TabPane>
                     <TabPane  tab={`${startDate1} - ${endDate1}`} key="next">
                         <Tabs defaultActiveKey="1">
                                         {this.context.nextWeekRoster.map((day, dayIndex) => {
                                             return <TabPane tab={day.label.slice(0, 3)} key={day.label}>
                            <div className="slot-overview">
                                {day.slots.map((slot,slotIndex) => {
                                    /* let slotInfo = slotCount.find(info => {
                                        return info.slotLabel === slot.label && info.day === dayIndex
                                    })
                                    if(slotIndex === 0 || slot.label !== day.slots[slotIndex - 1].label) {
                                        return <React.Fragment><h3>{slot.label} - <span>{slotInfo.filled}/{slotInfo.total}</span></h3><hr></hr>{slot.isFilled? <span>{slot.filledByName}</span>: null}</React.Fragment>
                                    }
                                    else if(slot.isFilled) {
                                        return <span>{slot.filledByName}</span>
                                    }
                                    else {
                                        return null;
                                    } */
                                    return <div className='slot-box'>{slot.filledBy.map(user => <span className='overview-user-row'>{user.userName}<button onClick={() => this.context.cancelSlot(dayIndex, slotIndex, user.userId, 'nextWeek', 'nextWeekRosterID', 'nextWeekRoster')}>cancel</button></span>)}<hr></hr><h3>{slot.label} - <span>{slot.filledBy.length}/{slot.maxSlotSize}</span></h3></div>
                                })}
                            </div>
                                             </TabPane>
                                         })}
                         </Tabs>
                     </TabPane>
         </Tabs>
    </div>;
  }
}
