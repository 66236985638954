import React, { Component } from 'react';
import { db } from '../../firebase/Firebase';

export default class Accounts extends Component {
  state = {
    phoneNumber: '',
    name: '',
    users: [],
    adminEmail:'team@tcsmailbox.com',
  }

  componentDidMount() { 
    db.collection('users').onSnapshot(querySnapshot => {
      let data = [];
        querySnapshot.forEach((doc) => {
          let info = doc.data();
          data.push({ ...info, userId: doc.id });
        });
        this.setState({users: data})
    })
   }

  onChange = (e) => {
    this.setState({[e.target.id]:e.target.value})
  }

  onUserDelete = (userId) => {
    fetch(
          "https://us-central1-ug-roster.cloudfunctions.net/widgets/delete-user",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({userId}),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            alert(data.message);
          });
  }

  makeAdmin = () => {
    fetch(
          "https://us-central1-ug-roster.cloudfunctions.net/widgets/addAdmin",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({email: this.state.adminEmail}),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            alert(data.message);
          });
  }

  onCreate = () => {
    let {phoneNumber, name} = this.state;
    fetch(
          "https://us-central1-ug-roster.cloudfunctions.net/widgets/create-user",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({phoneNumber:`+65${phoneNumber}`, name}),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            alert(data.message);
          });
  }
  render() {
    return <div className='accounts-page'>
      {/* <button onClick={this.makeAdmin}>make admin</button> */}
        <div className="create-user">
            <h2>Create New User</h2>
            <hr />
            <div className="form-input">
                <label htmlFor="phoneNumber">Mobile Number</label>
                <input onChange={this.onChange} type="number" name="phoneNumber" id="phoneNumber" />
            </div>
            <div className="form-input">
                <label htmlFor="name">Name</label>
                <input onChange={this.onChange} type="text" name="name" id="name" />
            </div>
            <button onClick={this.onCreate}>Create</button>
        </div>
        <div className="user-list">
          {this.state.users.map(user => {
            return <div className="user-row"><span className='user-row-name'>{user.name}</span><button onClick={() => this.onUserDelete(user.userId)} className="delete-user">delete</button></div>
          })}
        </div>
    </div>;
  }
}
