import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const config = {
  apiKey: "AIzaSyBmJpqLhT-ss4m7A37j4EtqzWp4I0WExQg",
  authDomain: "ug-roster.firebaseapp.com",
  projectId: "ug-roster",
  storageBucket: "ug-roster.appspot.com",
  messagingSenderId: "519275344745",
  appId: "1:519275344745:web:3251746cc0d7ffb62a83f8"
};

const fire = firebase.initializeApp(config);
export let auth = firebase.auth();
export let db = firebase.firestore();
export default fire;