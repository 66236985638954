import React, { Component } from 'react';
import { AppContext } from '../../context/AppContext';
import { Tabs } from 'antd';

const { TabPane } = Tabs;


export default class Settings extends Component {
  static contextType = AppContext;
  render() {
    let options = {month: 'short', day: 'numeric'};
    let startDate = new Date(this.context.nextWeek).toLocaleString('en-US', options);
    let endDate = new Date(this.context.nextWeek);
    endDate.setDate(endDate.getDate() + 6);
    endDate = endDate.toLocaleString('en-US', options);
    return <div className='settings-page'>
      <h3 className='week-of'><span className="highlight">{startDate} - {endDate}</span></h3>
        <Tabs defaultActiveKey="1">
            {this.context.nextWeekRoster.map((day, dayIndex) => {
                return <TabPane tab={day.label.slice(0, 3)} key={day.label}>
                    <div className="slot-list">
                        {day.slots.map((slot,slotIndex) => {
                          return <div className='slot-row'>
                            <span>{slot.label}</span>
                            <input type="number" onChange={(e) => this.context.editSlotSize(dayIndex, slotIndex, e.target.value)} name="maxSlotSize" id="maxSlotSize" value={slot.maxSlotSize}/>
                            
                          </div>
                        })}
                    </div>
                </TabPane>
            })}
        </Tabs>
    </div>;
  }
}
