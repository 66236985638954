import React, { Component } from "react";
import { Route, Link } from "react-router-dom";
import { Drawer, } from "antd";
import Pick from './user/Pick';
import Schedule from './user/Schedule';
import Overview from './admin/Overview';
import NavBar from "./NavBar";
import "antd/dist/antd.css";
import Settings from "./admin/Settings";
import Accounts from "./admin/Accounts";
import { auth } from "../firebase/Firebase";
import { AppContext } from "../context/AppContext";

export default class Main extends Component {
  static contextType = AppContext;
  state = { visible: false, placement: "left" };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div id="mains">
          <NavBar click={this.state.visible ? this.onClose : this.showDrawer}></NavBar>
          <Drawer
          title="Navigation"
          placement={this.state.placement}
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          {!this.context.admin ? 
          <React.Fragment>
            <Link onClick={this.onClose} to="/main/user/pick"><h3>PICK SLOTS</h3></Link>
            <Link onClick={this.onClose} to="/main/user/schedule"><h3>SCHEDULE</h3></Link>
          </React.Fragment>:
          <React.Fragment>
            <Link onClick={this.onClose} to="/main/admin/overview"><h3>OVERVIEW</h3></Link>
            <Link onClick={this.onClose} to="/main/admin/settings"><h3>SETTINGS</h3></Link>
            <Link onClick={this.onClose} to="/main/admin/accounts"><h3>ACCOUNTS</h3></Link>
          </React.Fragment>}
          <button onClick={() => {
            auth.signOut().then(() => {
                // Sign-out successful.
              }).catch((error) => {
                // An error happened.
              });
          }}>logout</button>
        </Drawer>
        {!this.context.admin ? 
        <React.Fragment>
          <Route
          path="/main/user/pick"
          render={(props) => <Pick {...props}></Pick>}
        ></Route>
        <Route
          path="/main/user/schedule"
          render={(props) => <Schedule {...props}></Schedule>}
        ></Route>
        </React.Fragment>: 
        <React.Fragment> <Route
          path="/main/admin/overview"
          render={(props) => <Overview {...props}></Overview>}
        ></Route>
        <Route
          path="/main/admin/settings"
          render={(props) => <Settings {...props}></Settings>}
        ></Route>
        <Route
          path="/main/admin/accounts"
          render={(props) => <Accounts {...props}></Accounts>}
        ></Route>
        </React.Fragment>}
        
       
      </div>
    );
  }
}