import React from "react";
import { Menu } from 'react-feather';


const NavBar = (props) => {
  return <div className="nav-bar">
    <button onClick={props.click}><Menu size={40}></Menu></button>
  </div>;
};

export default NavBar;