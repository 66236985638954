import React, { Component } from 'react';
import { AppContext } from '../../context/AppContext';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export default class Schedule extends Component {
  static contextType = AppContext;
  render() {
    let options = {month: 'short', day: 'numeric'};
    console.log(this.context.currentWeek.getDate())
    let startDate = new Date(this.context.currentWeek).toLocaleString('en-US', options);
    let endDate = new Date(this.context.currentWeek);
    endDate.setDate(endDate.getDate() + 6);
    endDate = endDate.toLocaleString('en-US', options);
    let startDate1 = new Date(this.context.nextWeek).toLocaleString('en-US', options);
    let endDate1 = new Date(this.context.nextWeek);
    endDate1.setDate(endDate1.getDate() + 6);
    endDate1 = endDate1.toLocaleString('en-US', options);
    return <div className='schedule-page'>
        <h3 className='week-of'><span className="highlight">{startDate} - {endDate}</span></h3>
        <Tabs defaultActiveKey="1">
            {this.context.currentWeekRoster.map((day, dayIndex) => {
                return <TabPane tab={day.label.slice(0, 3)} key={day.label}>
                    <div className="slot-overview">
                        {day.slots.map((slot,slotIndex) => {
                            if(slot.filledBy.findIndex(ele => ele.userId === this.context.user.userId) !== -1) {
                                return <div className='sch-row'><span>{slot.label}</span></div>
                            }
                            else {
                                return null;
                            }
                        })}
                    </div>
                </TabPane>
            })}
        </Tabs>
        <h3 className='week-of'><span className="highlight">{startDate1} - {endDate1}</span></h3>
        <Tabs defaultActiveKey="1">
            {this.context.nextWeekRoster.map((day, dayIndex) => {
                return <TabPane tab={day.label.slice(0, 3)} key={day.label}>
                    <div className="slot-overview">
                        {day.slots.map((slot,slotIndex) => {
                            if(slot.filledBy.findIndex(ele => ele.userId === this.context.user.userId) !== -1) {
                                return <div className='sch-row'><span>{slot.label}</span></div>
                            }
                            else {
                                return null;
                            }
                        })}
                    </div>
                </TabPane>
            })}
        </Tabs>
    </div>;
  }
}
