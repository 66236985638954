import React, { Component } from "react";
import { auth } from "../firebase/Firebase";
import firebase from 'firebase/compat/app';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export default class Login extends Component {
  state = {
    email: "",
    password: "",
    mobile: "",
    waitOTP: false,
  };

  setUp = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    'size': 'invisible',
    'callback': (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      console.log('solved');
      this.handleSubmit();
    }
  });
  }
  

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleUserSubmit = e => {
    e.preventDefault();
    this.setUp();
    console.log('pressed');
    const phoneNumber = `+65${this.state.mobile}`;
    const appVerifier = window.recaptchaVerifier;
    auth.signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          this.setState({waitOTP: true})
  // ...
        
          // ...
        }).catch((error) => {
          // Error; SMS not sent
          // ...
          console.log(error);
        });
  };
  handleAdminSubmit = e => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
        console.log(errorMessage);
      });
  };

  submitOTP = () => {
    const code = this.state.otp;
          window.confirmationResult.confirm(code).then((result) => {
          // User signed in successfully.
          console.log('signed in')
          const user = result.user;
          console.log(user);
    }).catch((error) => {
          // User couldn't sign in (bad verification code?)
          console.log(error);
          // ...
        });
  }
  render() {
    return (
      <div className="login">
        {!this.state.waitOTP ? <Tabs defaultActiveKey="1">
          <TabPane tab="Part-Timer" key="user">
            <form onSubmit={this.handleUserSubmit}>

              <div id="recaptcha-container"></div>
            
              <div className="form-group">
                <label>Mobile Number</label>
                <input
                  type="text"
                  id="mobile"
                  value={this.state.mobile}
                  onChange={this.onChange}
                ></input>
              </div>
              <button type="submit">Get OTP</button>
            </form>
          </TabPane>
          <TabPane tab="Admin" key="admin">
            <form onSubmit={this.handleAdminSubmit}>
              <div id="recaptcha-container"></div>
              <div className="form-group">
                <label>email</label>
                <input
                  type="email"
                  id="email"
                  value={this.state.email}
                  onChange={this.onChange}
                ></input>
              </div>
              <div className="form-group">
                <label>password</label>
                <input
                  type="password"
                  id="password"
                  value={this.state.password}
                  onChange={this.onChange}
                ></input>
              </div>
              <button type="submit">login</button>
            </form>
          </TabPane>
        </Tabs>: <div className="form-group">
          <label htmlFor="otp">Enter OTP</label>
          <input type="text" id="otp" value={this.state.otp} onChange={this.onChange}/>
          <button onClick={this.submitOTP}>Submit</button>
        </div>}
      </div>
    );
    }

  }