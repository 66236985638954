import React, { Component } from 'react';
import { AppContext } from '../../context/AppContext';
import { Tabs, Modal } from 'antd';

const { TabPane } = Tabs;

export default class Pick extends Component {
  static contextType = AppContext;

  state = {
      selectedSlots:  [],
      isModalVisible: false,
      confirmLoading: false,
  }

  addRemoveSlot = (dayIndex, slotIndex, slot) => {
      let currentSelectedSlots = [...this.state.selectedSlots];
      console.log(currentSelectedSlots);
      let doesSlotExistIndex = currentSelectedSlots.findIndex(ele => ele.label === slot.label && ele.dayIndex === dayIndex);
      console.log(doesSlotExistIndex);
      if(doesSlotExistIndex !== -1) {
        currentSelectedSlots.splice(doesSlotExistIndex, 1);
      }
      else {
          currentSelectedSlots.push({...slot, dayIndex, slotIndex});
      }

      this.setState({ selectedSlots: currentSelectedSlots });
      
  }

  toggleSubmit = () => {
    this.setState({ 
        isModalVisible: !this.state.isModalVisible
     });
    
  }

  
  render() {
    let {isModalVisible, selectedSlots} = this.state;
    let options = {month: 'short', day: 'numeric'};
    let startDate = new Date(this.context.nextWeek).toLocaleString('en-US', options);
    let endDate = new Date(this.context.nextWeek);
    endDate.setDate(endDate.getDate() + 6);
    endDate = endDate.toLocaleString('en-US', options);

    return <div className="pick-page">
        {this.context.availableToBook ? <React.Fragment>
            <Modal
                title="Submit Timeslots"
                visible={isModalVisible}
                onOk={() => {
                    this.setState({confirmLoading: true});
                    setTimeout(() => {
                        this.toggleSubmit();
                        this.setState({confirmLoading: false})
                        this.context.submitSlots(selectedSlots);
                    }, 2000);

                }}
                confirmLoading={this.state.confirmLoading}
                onCancel={this.toggleSubmit}>
                <div className="slot-summary">
                    {selectedSlots.map(slot => {
                        let slotDate = new Date(this.context.nextWeek);
                        slotDate.setDate(slotDate.getDate() + slot.dayIndex);
                        slotDate = slotDate.toLocaleString('en-US', options)
                        return <div key={`${slotDate}${slot.label}`} className="summary-row"><span>{slotDate}</span><span>{slot.label}</span></div>
                    })}
                </div>
            </Modal>
            <button className='updateSlots' onClick={this.toggleSubmit}>Save</button>
            <h3 className='week-of'><span className="highlight">{startDate} - {endDate}</span></h3>
            <Tabs defaultActiveKey="1">
                {this.context.nextWeekRoster.map((day, dayIndex) => {
                    return <TabPane tab={day.label.slice(0, 3)} key={day.label}>
                        <div className="slot-grid">
                            {day.slots.map((slot,slotIndex) => {
                               /*  if(slotIndex === 0) {
                                    if(slot.isFilled) {
                                        return null;
                                    }
                                    else if(slot.)
                                }
            
                                if(!slot.isFilled) {
                                    return <button key={`${slot.label}-${slotIndex}`} onClick={() => this.context.handleAdd(dayIndex, slotIndex, !slot.isFilled)} style={slot.isFilled ? {backgroundColor: 'green'}:{}}>{slot.label}</button>
                                }
                                else {
                                    return null;
                                } */
                                if(slot.filledBy.length < slot.maxSlotSize) {
                                    if(slot.filledBy.findIndex(ele => ele.userId === this.context.user.userId) === -1) {
                                        return <button
                                        key={`${slot.label}-${slotIndex}`}
                                        onClick={() => this.addRemoveSlot(dayIndex, slotIndex, slot)}
                                        style={this.state.selectedSlots.findIndex(ele => ele.label === slot.label && ele.dayIndex === dayIndex) !== -1 ? {backgroundColor: 'green'}:{}}>{slot.label}
                                        </button>
                                    }
                                    else {
                                        return null;
                                    }
                                }
                            })}
                        </div>
                    </TabPane>
                })}
            </Tabs>
        </React.Fragment>:<div><span>Booking of slots is currently closed.</span></div>}
    </div>;
  }
}
