import React, { Component } from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import "./styles/App.css";
import {auth} from './firebase/Firebase';
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Main from "./components/Main";
import { AppContext } from "./context/AppContext";

class App extends Component {
  static contextType = AppContext;
  state = {
    auth: true,
  };
  componentDidMount() {
    /*auth
      .createUserWithEmailAndPassword("andreweijie@gmail.com", "Many@1234")
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
        console.log(errorMessage);
      });*/
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then(idTokenResult => {
          if(idTokenResult.claims.admin) {
            this.context.setAdmin(true);
          }
          else {
            this.context.setAdmin(false);
          }
        })
        this.setState({ auth: true });
        this.context.setUser(user);
        this.props.history.replace("/main");
      } else {
        this.setState({ auth: false });
      }
    });
  }

  render() {
    return (
        <div className="App">
          <span id="ver">0.6.3</span>
          <Route exact path="/" render={() => <Redirect to="/main" />} />
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <PrivateRoute
            path="/main"
            component={Main}
            auth={this.state.auth}
          ></PrivateRoute>
        </div>
    );
  }
}

export default withRouter(App);