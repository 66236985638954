import React, { Component, createContext } from "react";
import {db} from "../firebase/Firebase";


export const AppContext = createContext();
const current = {
  weekStartDate: new Date(),
  roster: [
    {   
        dayOfWeek: 1,
        label: 'Monday',
        slots: [
            {
                label: '10am - 11am',
                startTime: '10am',
                endTime: '11am',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '11am - 4pm',
                startTime: '11am',
                endTime: '4pm',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '4pm - 10pm',
                startTime: '4pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '6pm - 10pm',
                startTime: '4pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 1
            },
            {
                label: '10pm - 11pm',
                startTime: '10pm',
                endTime: '11pm',
                filledBy: [],
                maxSlotSize: 2
            },
        ]
    },
    {   
        dayOfWeek: 2,
        label: 'Tuesday',
        slots: [
            {
                label: '10am - 11am',
                startTime: '10am',
                endTime: '11am',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '11am - 4pm',
                startTime: '11am',
                endTime: '4pm',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '4pm - 10pm',
                startTime: '4pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '6pm - 10pm',
                startTime: '4pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 1
            },
            {
                label: '10pm - 11pm',
                startTime: '10pm',
                endTime: '11pm',
                filledBy: [],
                maxSlotSize: 2
            },
        ]
    },
    {   
        dayOfWeek: 3,
        label: 'Wednesday',
        slots: [
            {
                label: '10am - 11am',
                startTime: '10am',
                endTime: '11am',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '11am - 4pm',
                startTime: '11am',
                endTime: '4pm',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '4pm - 10pm',
                startTime: '4pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '6pm - 10pm',
                startTime: '4pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 1
            },
            {
                label: '10pm - 11pm',
                startTime: '10pm',
                endTime: '11pm',
                filledBy: [],
                maxSlotSize: 2
            },
        ]
    },
    {   
        dayOfWeek: 4,
        label: 'Thurs',
        slots: [
            {
                label: '10am - 11am',
                startTime: '10am',
                endTime: '11am',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '11am - 4pm',
                startTime: '11am',
                endTime: '4pm',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '4pm - 10pm',
                startTime: '4pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '6pm - 10pm',
                startTime: '4pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 1
            },
            {
                label: '10pm - 11pm',
                startTime: '10pm',
                endTime: '11pm',
                filledBy: [],
                maxSlotSize: 2
            },
        ]
    },
    {   
        dayOfWeek: 5,
        label: 'Friday',
        slots: [
            {
                label: '10am - 11am',
                startTime: '10am',
                endTime: '11am',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '11am - 4pm',
                startTime: '11am',
                endTime: '4pm',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '4pm - 10pm',
                startTime: '4pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '6pm - 10pm',
                startTime: '4pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 1
            },
            {
                label: '10pm - 11pm',
                startTime: '10pm',
                endTime: '11pm',
                filledBy: [],
                maxSlotSize: 2
            },
        ]
    },
    {   
        dayOfWeek: 6,
        label: 'Saturday',
        slots: [
            {
                label: '8am - 9am',
                startTime: '8am',
                endTime: '9am',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '9am - 10pm',
                startTime: '9am',
                endTime: '10am',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '10am - 5pm',
                startTime: '10am',
                endTime: '5pm',
                filledBy: [],
                maxSlotSize: 7
            },
            {
                label: '5pm - 10pm',
                startTime: '5pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 7
            },
            {
                label: '10pm - 11pm',
                startTime: '10pm',
                endTime: '11pm',
                filledBy: [],
                maxSlotSize: 3
            },
        ]
    },
    {   
        dayOfWeek: 7,
        label: 'Sunday',
        slots: [
            {
                label: '8am - 9am',
                startTime: '8am',
                endTime: '9am',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '9am - 10pm',
                startTime: '9am',
                endTime: '10am',
                filledBy: [],
                maxSlotSize: 2
            },
            {
                label: '10am - 5pm',
                startTime: '10am',
                endTime: '5pm',
                filledBy: [],
                maxSlotSize: 7
            },
            {
                label: '5pm - 10pm',
                startTime: '5pm',
                endTime: '10pm',
                filledBy: [],
                maxSlotSize: 7
            },
            {
                label: '10pm - 11pm',
                startTime: '10pm',
                endTime: '11pm',
                filledBy: [],
                maxSlotSize: 3
            },
        ]
    },
]
}
export default class AppContextProvider extends Component {
  state = {
    outlet: "TCS",
    roster: [],
    rosterCopy: [],
    user: {userName: '', userId: ""},
    userSlots: [],
    week: new Date(),
    admin: false,
    currentWeek: new Date(),
    nextWeek: new Date(),
    currentWeekRoster: [],
    nextWeekRoster: [],
    availableToBook: false,
    version: '0.6.3'
  };

  componentDidMount() {
    /* db.collection("current").doc("next").set(current); */

    db.collection("current")
    .where("weekStartDate", ">=",  this.getMonday(new Date()))
    .where("weekStartDate", "<=",  this.getTuesday(new Date()))
    .onSnapshot((querySnapShot) => {
                    let data = [];
                    querySnapShot.forEach((doc) => {
                    let info = doc.data();
                    data.push({ ...info, id: doc.id });
                    });
                    this.setState({
                        currentWeek: data[0].weekStartDate.toDate(),
                        currentWeekRoster: data[0].roster,
                        currentWeekRosterID: data[0].id
                    });
                });

    db.collection("current")
    .where("weekStartDate", ">=",  this.getMonday(new Date(new Date().setDate(new Date().getDate() + 7)), 0))
    .where("weekStartDate", "<=",  this.getTuesday(new Date(new Date().setDate(new Date().getDate() + 7)), 2))
    .onSnapshot((querySnapShot) => {
                    let data = [];
                    querySnapShot.forEach((doc) => {
                    let info = doc.data();
                    data.push({ ...info, id: doc.id });
                    });
                    this.setState({
                        nextWeek: data[0].weekStartDate.toDate(),
                        nextWeekRoster: data[0].roster,
                        nextWeekRosterID: data[0].id
                    });
                });
    db.collection("settings").doc('booking').get().then(doc => {
        if (doc.exists) {
            let data = doc.data();
            this.setState({availableToBook: data.availableToBook, versionCheck: data.version})
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }, () => {
        if(this.state.versionCheck !== this.state.version) {
            window.location.reload(true);
        }
    })
  }

  getMonday =(d) => {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -7:0); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

getTuesday =(d) => {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -5:2); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

 getFirstDayOfWeek(d) {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day;

  return new Date(date.setDate(diff));
}

  setUser = (user) => {
    this.setState({user: {userName: user.multiFactor.user.displayName, userId: user.multiFactor.user.uid}})
  }
  
  setAdmin = (bool) => {
    this.setState({admin: bool})
  }

  setOutlet = (outlet) => {
    this.setState({
      outlet,
    });
  };

  editSlotSize = (dayIndex, slotIndex, newSize) => {
    let newRoster = [...this.state.nextWeekRoster];
    newRoster[dayIndex].slots[slotIndex].maxSlotSize = parseInt(newSize);

    db.collection('current').doc(this.state.nextWeekRosterID).update( { roster: newRoster });
  }

  submitSlots = (selectedSlots) => {
    /* let newRoster = [...this.state.rosterCopy];
    console.log(newRoster);
    let dayCopy = {...newRoster[dayIndex]};
    let newSlot = {...dayCopy.slots[slotIndex], isFilled: fillOrRemove, filledByName: fillOrRemove ? this.state.user.userName : "nil", filledById: fillOrRemove ? this.state.user.userId: "nil"};

    dayCopy.slots.splice(slotIndex, 1, newSlot);
    newRoster.splice(dayIndex, 1, dayCopy);

    this.setState({ rosterCopy: newRoster }); */
    /* let newRoster = [...this.state.rosterCopy];
    let dayCopy = {...newRoster[dayIndex]};
    let oldFilledBy = [...dayCopy.slots[slotIndex].filledBy];
    if(fillOrRemove) {
        oldFilledBy.push(this.state.user);
    }
    else {
        oldFilledBy.splice(oldFilledBy.findIndex(ele => ele.userId === this.state.user.userId) ,1)
    }
    newRoster[dayIndex].slots[slotIndex].filledBy = oldFilledBy;

    this.setState({ rosterCopy: newRoster }); */
    let newRoster = [...this.state.nextWeekRoster];

    selectedSlots.map(slot => {
        if(newRoster[slot.dayIndex].slots[slot.slotIndex].filledBy.findIndex(el => el.userId === this.state.user.userId) === -1) {
            newRoster[slot.dayIndex].slots[slot.slotIndex].filledBy = [...newRoster[slot.dayIndex].slots[slot.slotIndex].filledBy, this.state.user];
        }
        
    })

    /* newRoster = newRoster.map((day, dayIndex) => {
        return {
            dayOfWeek: day.dayOfWeek,
            label: day.label,
            slots: day.slots.map((currentSlot, slotIndex) => {
            if(selectedSlots.findIndex(slot => slot.label === currentSlot.label && slot.dayIndex === dayIndex) !== -1) {
                currentSlot.filledBy = [...currentSlot.filledBy, this.state.user];
                return currentSlot;
            }
            else {
                return currentSlot;
            }
        })
        }
    }) */
    
    console.log(newRoster);

    db.collection('current').doc(this.state.nextWeekRosterID).update( {weekStartDate: this.state.nextWeek, roster: newRoster});
  }

  cancelSlot = (removeDayIndex, removeSlotIndex, userId, week, weekId, weekRoster) => {
    console.log("removing")

    let newRoster = [...this.state[weekRoster]];
    newRoster = newRoster.map((day, dayIndex) => {
        return {
            dayOfWeek: day.dayOfWeek,
            label: day.label,
            slots: day.slots.map((currentSlot, slotIndex) => {
            if(dayIndex === removeDayIndex && slotIndex === removeSlotIndex) {
                console.log("found")
                currentSlot.filledBy.splice(currentSlot.filledBy.findIndex(ele => ele.userId === userId), 1);
                return currentSlot;
            }
            else {
                return currentSlot;
            }
        })
        }
    })

    db.collection('current').doc(this.state[weekId]).update( {weekStartDate: this.state[week], roster: newRoster});
  }
  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setOutlet: this.setOutlet,
          submitSlots: this.submitSlots,
          updateDB: this.updateDB,
          setUser: this.setUser,
          setAdmin: this.setAdmin,
          editSlotSize: this.editSlotSize,
          cancelSlot: this.cancelSlot
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

